import {
    registerApplication,
    getAppStatus,
    start,
    navigateToUrl
} from 'single-spa';
import * as isActive from './activity-functions';
import * as Eev from 'eev';
import { i18next } from '@provider-portal/i18n';

import './styles.scss';

window.addEventListener('single-spa:app-change', () => {
    const status = getAppStatus('@provider-portal/layout');
    if (status == 'MOUNTED') {
        let sectionContainer = document.getElementById('section-container');
        let contentWrapper = document.getElementById('content-wrapper');

        if (contentWrapper.parentElement.id !== 'section-container') {
            if (sectionContainer) sectionContainer.appendChild(contentWrapper);
        }

        let sectionSearch = document.getElementById('section-search');
        let searchWrapper = document.getElementById('search-wrapper');

        if (
            sectionSearch &&
            searchWrapper &&
            searchWrapper.parentElement.id !== sectionSearch.id
        ) {
            sectionSearch.appendChild(searchWrapper);
        }
    }
});

if (
    !window.sessionStorage.getItem('ProviderPortal') ||
    window.name !== window.sessionStorage.getItem('ProviderPortal')
) {
    var innovaPageName = 'ProviderPortal-' + Math.floor(Math.random() * 100);
    window.name = innovaPageName;
    window.sessionStorage.setItem('ProviderPortal', innovaPageName);
}

const eventBus = new Eev();

registerApplication(
    '@provider-portal/oidc',
    () => System.import('@provider-portal/oidc'),
    isActive.oidc,
    { eventBus }
);

registerApplication(
    '@provider-portal/landing',
    () => System.import('@provider-portal/landing'),
    isActive.landing,
    { eventBus }
);
i18next
    .then(() => {
        start({
            urlRerouteOnly: true
        });
    })
    .catch(err =>
        console.error('Failed to initialize i18next translation', err)
    );

eventBus.on('remove-stored-data', function removeUserData() {
    sessionStorage.removeItem('search');
});

eventBus.on('oidc-user-authenticated', function registerApplications(data) {
    eventBus.off('oidc-user-authenticated', registerApplications);

    //Register all Micro-Frontends that require the user to be authenticated.
    registerApplication(
        '@provider-portal/layout',
        () => System.import('@provider-portal/layout'),
        isActive.layout,
        { eventBus }
    );

    registerApplication(
        '@provider-portal/home-selector',
        () => System.import('@provider-portal/home-selector'),
        isActive.home,
        { eventBus }
    );

    registerApplication(
        '@provider-portal/smartpaper',
        () => System.import('@provider-portal/smartpaper'),
        isActive.smartpaper,
        { eventBus }
    );

    registerApplication(
        '@provider-portal/caregapsqueue',
        () => System.import('@provider-portal/caregapsqueue'),
        location => {
            return isActive.caregapsqueue(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/closegaps',
        () => System.import('@provider-portal/closegaps'),
        location => {
            return isActive.closegaps(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/adt-daily-discharges-report',
        () => System.import('@provider-portal/adt-daily-discharges-report'),
        location => {
            return isActive.adtDailyDischargesReport(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/fhir-adt-completeness-report',
        () => System.import('@provider-portal/fhir-adt-completeness-report'),
        location => {
            return isActive.fhirAdtCompletenessReport(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/fhir-adt-transactions-report',
        () => System.import('@provider-portal/fhir-adt-transactions-report'),
        location => {
            return isActive.fhirAdtTransactionsReport(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/hospital-quality-report',
        () => System.import('@provider-portal/hospital-quality-report'),
        location => {
            return isActive.hospitalQualityReport(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/adt-hospital-master-connectivity-report',
        () =>
            System.import(
                '@provider-portal/adt-hospital-master-connectivity-report'
            ),
        location => {
            return isActive.adtHospitalMasterConnectivityReport(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/deeplink',
        () => System.import('@provider-portal/deeplink'),
        location => {
            return isActive.deeplink(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/message',
        () => System.import('@provider-portal/message'),
        location => {
            return isActive.message(location);
        },
        { eventBus: eventBus }
    );

    registerApplication(
        '@provider-portal/inbox-notifications',
        () => System.import('@provider-portal/inbox-notifications'),
        isActive.inbox,
        { eventBus }
    );

    registerApplication(
        '@provider-portal/notifications',
        () => System.import('@provider-portal/notifications'),
        isActive.notifications,
        { eventBus }
    );

    registerApplication(
        '@provider-portal/claims',
        () => System.import('@provider-portal/claims'),
        location => {
            return isActive.claims(location);
        },
        { eventBus }
    );

    registerApplication(
        '@provider-portal/claims-queue',
        () => System.import('@provider-portal/claims-queue'),
        isActive.claimsQueue,
        { eventBus }
    );

    // eslint-disable-next-line no-undef
    let redirectUrl = `${window.location.pathname}#${process.env.DEFAULT_URL}`;
    if (data && data.redirect && isActive.anyAuthenticated(data.redirect)) {
        redirectUrl = data.redirect;
    }

    navigateToUrl(redirectUrl);

    if (data.removeStoredData) eventBus.emit('remove-stored-data');
});
