export function prefix(location, ...prefixes) {
    return (
        location &&
        prefixes.some(prefix => location.href.indexOf(`/${prefix}`) !== -1)
    );
}

function except(location, ...prefixes) {
    return (
        location &&
        prefixes.every(prefix => location.href.indexOf(`/${prefix}`) === -1)
    );
}

export function layout(location) {
    return except(location, 'landing');
}

export function home() {
    return prefix(location, 'home');
}

export function smartpaper() {
    return prefix(location, 'smartpaper');
}

export function caregapsqueue(location) {
    return prefix(location, 'caregapsqueue');
}

export function closegaps(location) {
    return prefix(location, 'closegaps');
}

export function homeSpecialist() {
    return prefix(location, 'specialist-home');
}

export function deeplink(location) {
    return prefix(location, 'deeplink');
}

export function message(location) {
    return prefix(location, 'message');
}

export function oidc() {
    return true;
}

export function landing() {
    return prefix(location, 'landing');
}

export function notifications() {
    return true;
}

export function adtDailyDischargesReport(location) {
    return prefix(location, 'adtdailydischargesnotifreport');
}

export function fhirAdtCompletenessReport(location) {
    return prefix(location, 'fhiradtcompletenessreport');
}

export function fhirAdtTransactionsReport(location) {
    return prefix(location, 'fhiradttransactionsreport');
}

export function hospitalQualityReport(location) {
    return prefix(location, 'hospitalqualityreport');
}

export function adtHospitalMasterConnectivityReport(location) {
    return prefix(location, 'adthospitalmasterconnectivityreport');
}

export function inbox() {
    return prefix(location, 'inbox');
}

export function claims(location) {
    return prefix(location, 'claims');
}

export function claimsQueue() {
    return prefix(location, 'queue');
}

export function anyAuthenticated(requestedUrl) {
    return [
        'home',
        'specialist-home',
        'smartpaper',
        'closegaps',
        'caregapsqueue',
        'adtdailydischargesnotifreport',
        'fhiradtcompletenessreport',
        'fhiradttransactionsreport',
        'hospitalqualityreport',
        'adthospitalmasterconnectivityreport',
        'deeplink',
        'message',
        'inbox',
        'claims',
        'queue'
    ].some(prefix => requestedUrl.indexOf(`/${prefix}`) !== -1);
}
